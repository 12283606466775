'use client'

// import 'crosswords-js/style/reset.css'
import 'crosswords-js/dist/crosswords.css'
import '~/styles/crosswords-js-extra.css'

import React from 'react'
import { type CrosswordControllerXInstance } from './controller'
import { ControllerInstance as _ControllerInstance, CrosswordDefinition } from 'crosswords-js/src/index.mjs'
import { PartyPenguinOverlayed as SuccessAnimationOverlayed, DrawingOverlayed } from '../animations'
import { CrosswordControllerX } from './controller'
import { Cheatbar } from './cheatbar'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// https://supabase.com/blog/supabase-realtime-multiplayer-general-availability

export type Crossword2Props = {
  definition: CrosswordDefinition
  onInitialized?: (params: { controller: CrosswordControllerXInstance }) => void
}

export const CrosswordWithQueryClient: typeof Crossword2 = (props) => {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <Crossword2 {...props} />
    </QueryClientProvider>
  )
}

export const Crossword2 = ({ definition, onInitialized }: Crossword2Props) => {
  const [status, setStatus] = React.useState<'loading' | 'playing' | 'complete'>('loading')
  const [currentClueText, setCurrentClueText] = React.useState(definition.acrossClues[0]?.clue)
  const [controller, setController] = React.useState<CrosswordControllerXInstance>()

  const gridParent = React.useRef<HTMLDivElement>(null)
  const cluesParent = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!gridParent.current || !cluesParent.current) return
    if (status !== 'loading') return
    if (gridParent.current.querySelector('.crossword-grid')) return // not sure why this is necessary but without it multiple crosswords are rendered
    const controller = CrosswordControllerX.from(definition, gridParent.current, cluesParent.current)

    controller.addEventsListener(['crosswordSolved'], () => setStatus('complete'))
    controller.addEventsListener(['clueSelected'], (ev) =>
      setCurrentClueText(`${ev.labelText} ${ev.clueText} ${ev.lengthText}`),
    )
    setStatus('playing')
    setController(controller)
    onInitialized?.({ controller })
  }, [Boolean(gridParent.current), Boolean(cluesParent.current)])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <h2>{(definition.source as any).titleComponent || definition.source.title}</h2>
        <div className={['clueShower', currentClueText && 'hasContent'].filter(Boolean).join(' ')}>
          {currentClueText}
        </div>
        <div className="gridContainer crosswords-js" style={{ position: 'relative' }}>
          <SuccessAnimationOverlayed
            className={status === 'complete' ? 'rotate-forever' : 'rotate-forever'}
            play={status === 'complete'}
            style={{
              width: 'calc(100% - 30px)',
              height: 'calc(100% - 30px)',
              ...(Math.random() < 0.5 && { '--rotation': '-360deg' }),
              display: 'none',
            }}
            onLoopComplete={() => setStatus('playing')}
          />
          <DrawingOverlayed
            play={status === 'loading'}
            loop={true}
            style={{ width: 'calc(100% - 30px)', height: 'calc(100% - 30px)' }}
          />
          <div ref={gridParent} />
          {controller && <Cheatbar controller={controller} />}
        </div>
        <div ref={cluesParent} />
      </div>
    </>
  )
}
