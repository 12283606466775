'use client'

import { useDB } from '~/db'
import { Crossword2 } from './crosswords-js'
import React from 'react'
import { useLocalStorage, useIsClient, useSessionStorage } from '@uidotdev/usehooks'
import { withoutAnswers } from './definition-fixers'
import { ControllerState, CrosswordControllerXInstance } from './controller'
import { ClueCell } from 'crosswords-js/src/index.mjs'
import { QueryClient, QueryClientProvider, useMutation, useQuery } from '@tanstack/react-query'
import { postEvents as postEvents } from '~/app/event/client'
import { SolutionEvent } from '~/app/event/types'
import { useToast } from '../ui/use-toast'
import { scoreValue } from '~/app/puzzles/[slug]/leaderboard/scoreValue'

const pick = <T extends object, K extends keyof T>(obj: T, keys: K[]) =>
  Object.fromEntries(keys.map((k) => [k, obj[k]])) as Pick<T, K>

type DBCrosswordProps = { puzzle: string } & Omit<React.ComponentProps<typeof Crossword2>, 'definition'>

export const DBCrossword = (props: DBCrosswordProps) => {
  // useLocalStorage throws - there could be a server-client nickname mismatch otherwise https://github.com/uidotdev/usehooks/issues/218#issuecomment-1681205155
  const isClient = useIsClient()
  if (!isClient) return null

  return (
    <QueryClientProvider client={new QueryClient()}>
      <_DBCrossword2
        onInitialized={({ controller }) => {
          document.title = `${controller.definition.source.title} - Verbalist`
        }}
        {...props}
      />
    </QueryClientProvider>
  )
}

interface SessionState {
  sessionId: string
  cursor: { x: number; y: number }
  state: ControllerState
}
interface BroadcastPayload extends SessionState {
  type: 'broadcast'
  event: string
}

const _DBCrossword2 = (props: DBCrosswordProps) => {
  const { supabase } = useDB()
  const [{ nick: nickname }, setNickname] = useLocalStorage('verbalist.solution_participant_nickname.v1', {
    nick: `anonymous_${Math.random()}`.replace('0.', ''),
  })

  // todo: see if we can use a builtin session id instead of generating our own
  const [sessionId] = useSessionStorage('verbalist.session_id.v1', String(Date.now() + Math.random()))
  const { data: puzzle, ...query } = useQuery({
    queryKey: [props.puzzle],
    queryFn: async () => {
      const { data: puz } = await supabase
        .from('puzzle')
        .select(`slug, data`)
        .eq('slug', props.puzzle)
        .maybeSingle()

      if (!puz) return null

      return {
        data: puz.data,
        json: puz.data && JSON.stringify(withoutAnswers(puz.data)),
      }
    },
  })

  const { toast } = useToast()
  const rankingMutation = useMutation({
    mutationFn: async (solutionId: string) => {
      const { data: score } = await supabase
        .from('solution_score')
        .select('*')
        .eq('solution_id', solutionId)
        .maybeSingle()
      const { data, count: betterScores } = await supabase
        .from('solution_score')
        .select('*', { count: 'exact', head: true })
        .lt('millis', Number(score?.millis) || 0)

      return alert(JSON.stringify({ solutionId, score, betterScores }))
    },
  })

  const puzzleJson = puzzle?.json
  const puzzleDef = React.useMemo(() => {
    const parsed = JSON.parse(puzzleJson || 'null')
    if (parsed) {
      parsed.source.titleComponent = (
        <>
          {parsed.source.title} - <a href={`/puzzles/${props.puzzle}/leaderboard`}>leaderboard</a>
        </>
      )
    }
    return parsed as NonNullable<typeof puzzle>['data']
  }, [puzzleJson])

  const onInitialized = React.useCallback(
    async ({ controller }: { controller: CrosswordControllerXInstance }) => {
      const searchParams = new URLSearchParams(window.location.search)
      const key = String(Date.now() + Math.random())

      const loadSolution2 = async () => {
        const { data: soln } = await supabase
          .from('solution')
          .upsert({
            id: searchParams.get('solution_id') || undefined,
            puzzle: props.puzzle,
            tags: [`host:${window.location.hostname}`, ...(searchParams.get('tag')?.split(',') || [])],
          })
          .select(`id, answers`)
          .single()

        addURLSearchParams({ solution_id: soln.id })

        const [{ data: existingParticipant }, { data: session }] = await Promise.all([
          supabase
            .from('participant')
            .select(`id, nickname`)
            .eq('nickname', nickname)
            .eq('solution_id', soln.id)
            .maybeSingle(),
          supabase.auth.getSession(),
        ])

        const { data: player } = await supabase
          .from('participant')
          .upsert({
            id: existingParticipant?.id,
            nickname,
            solution_id: soln.id,
            user_id: session.session?.user?.id,
          })
          .select()
          .single()

        return { ...soln, player }
      }

      const ghostRace = async () => {
        const raceSolution = searchParams.get('race')
        if (!raceSolution) return
        const { data: events } = await supabase
          .from('solution_event')
          .select('created_at, type, detail, player!inner(*)')
          .eq('player.solution_id', raceSolution)
          .order('id')

        if (!events[0]) return console.log('no events')

        const highlighter = controller.crupdateHiglighter(`race_${raceSolution}`, { color: 'grey' })
        for (const [i, ev] of events.map((e, i) => [i, e] as const)) {
          const event = ev as SolutionEvent & Omit<typeof ev, keyof SolutionEvent>
          if (event.type === 'cursor_moved' || event.type === 'cell_updated') {
            highlighter.move(event.detail)
            const modelCell = controller.model.cells[event.detail.x][event.detail.y]
            const ghostSolved = event.type === 'cell_updated' && modelCell.solution === event.detail.value
            if (ghostSolved) {
              modelCell?.cellElement?.classList.add('ghost-solved')
            }
          }
          if (!events[i + 1]) continue
          const sleepForMs = new Date(events[i + 1]!.created_at).getTime() - new Date(ev.created_at).getTime()
          await new Promise((resolve) => setTimeout(resolve, sleepForMs))
        }
      }

      type LoadedSolution = Awaited<ReturnType<typeof loadSolution2>>

      const setupEvents = (solution: LoadedSolution) => {
        const player = solution.player.id
        const channel = supabase.channel(`solution:${solution!.id}`, {
          config: { presence: { key } },
        })
        channel
          .on('broadcast', { event: '*' }, (data) => {
            const { ...payload } = data as BroadcastPayload
            console.log({ payload: payload, data })
            controller.restore(payload.state)
            const highlighter = controller.crupdateHiglighter(payload.sessionId, {
              color: 'goldenrod',
              label: JSON.stringify(payload.sessionId.toString().slice(0, 2)),
            })
            highlighter.move(payload.cursor)
          })
          .on<SessionState>('presence', { event: 'join' }, ({ newPresences }) => {
            const others = newPresences.filter((p) => p.sessionId !== sessionId)
            others.forEach((p) => {
              const highlighter = controller.crupdateHiglighter(p.sessionId, {
                color: 'goldenrod',
                label: JSON.stringify(p.sessionId.toString().slice(0, 2)),
              })
              highlighter.move(p.cursor)
            })
          })
          .on<SessionState>('presence', { event: 'leave' }, ({ key, leftPresences }) => {
            // todo: figure out why this setTimeout is necessary/why 'leave' is constantly firing - if we remove immediately it works but there's no animation because every movement removes and recreates the highlighter
            setTimeout(() => {
              const newState = channel.presenceState<SessionState>()
              const activeSessions = new Set(
                Object.values(newState).flatMap((presence) => presence.map((p) => p.sessionId)),
              )
              leftPresences
                .filter((p) => !activeSessions.has(p.sessionId))
                .forEach((p) => controller.removeHighlighter(p.sessionId))
            }, 100)
          })
          .subscribe(async (status) => {
            if (status !== 'SUBSCRIBED') return

            await channel.track({
              cursor: { x: controller.currentCell.x, y: controller.currentCell.y },
              sessionId,
              state: controller.serialize(),
            } satisfies SessionState)
          })

        const broadcastCellUpdate = async (payload: { x: number; y: number; value: string }) => {
          const state = controller.serialize()
          channel.send({
            type: 'broadcast',
            event: 'cellValueUpdated',
            cursor: pick(payload, ['x', 'y']),
            state: state,
            sessionId,
          } satisfies BroadcastPayload)
          await supabase
            .from('solution')
            .update({ answers: state }) //
            .eq('id', solution.id)
        }
        const trackCellUpdateEvent: typeof broadcastCellUpdate = async (payload) => {
          await postEvents({
            player: player,
            events: [
              {
                type: 'cell_updated',
                detail: pick(payload, ['x', 'y', 'value']),
              },
            ],
          })
        }
        const broadcastCurrentCellUpdate = async (cell: ClueCell) => {
          await channel.send({
            type: 'broadcast',
            event: 'currentCellUpdated',
            cursor: pick(cell, ['x', 'y']),
            state: controller.serialize(),
            sessionId,
          } satisfies BroadcastPayload)
        }
        const trackCurrentCellUpdateEvent: typeof broadcastCurrentCellUpdate = async (cell) => {
          await postEvents({
            player,
            events: [
              {
                type: 'cursor_moved',
                detail: pick(cell, ['x', 'y']),
              },
            ],
          })
        }

        controller.events.on('cellValueUpdated', broadcastCellUpdate)
        controller.events.on('cellValueUpdated', trackCellUpdateEvent)
        controller.events.on('currentCellUpdated', broadcastCurrentCellUpdate)
        controller.events.on('currentCellUpdated', trackCurrentCellUpdateEvent)
        controller.addEventsListener(
          ['cellRevealed', 'clueCleaned', 'clueSolved', 'clueRevealed', 'crosswordRevealed'],
          // sending too many broadcast events in quick succession can cause the server to drop them. update the current cell when higher-level events happen too
          () => setTimeout(() => broadcastCurrentCellUpdate(controller.currentCell), 100),
        )
        controller.addEventsListener(['crosswordSolved'], async () => {
          const send = async () => {
            await postEvents({
              player: player,
              events: [{ type: 'completed', detail: { xy: controller.xy() } }],
            })
          }
          setTimeout(send, 100)
        })
        controller.addEventsListener(['crosswordSolved'], async () => {
          await new Promise((r) => setTimeout(r, 1000))
          const { data: score } = await supabase
            .from('solution_score')
            .select('*')
            .eq('solution_id', solution.id)
            .maybeSingle()
          const { count: betterScores } = await supabase
            .from('solution_score')
            .select('*', { count: 'exact', head: true })
            .eq('puzzle', props.puzzle)
            .lt('millis', Number(score?.millis) || 0)

          if (!score) return

          const rank = (betterScores || 0) + 1
          toast({
            className: 'top-0',
            title: 'Crossword solved!',
            description: `You solved it in ${scoreValue(score)}, the #${rank} fastest time everr`,
            duration: 10_000,
          })
        })
        return channel
      }

      const solution = await loadSolution2()

      await postEvents({
        player: solution.player.id,
        events: [{ type: 'started' }],
      })

      if (solution?.answers) {
        controller.restore(solution.answers)
      }

      setupEvents(solution)
      ghostRace()

      props.onInitialized?.({ controller })
    },
    [],
  )

  if (!puzzleDef && query.isSuccess) {
    return <pre style={{ display: 'flex', justifyContent: 'center' }}>{props.puzzle}: puzzle not found</pre>
  }

  if (!puzzleDef) {
    return (
      <pre style={{ display: 'flex', justifyContent: 'center' }}>
        {props.puzzle}: {query.status}
      </pre>
    )
  }

  return (
    <>
      <Crossword2 definition={puzzleDef} onInitialized={onInitialized} />
    </>
  )
}

export const addURLSearchParams = (params: Record<string, string>) => {
  const url = new URL(window.location.href)
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value)
  })
  window.history.replaceState({}, '', url)
}
