/**
 * - creates an element and returns it along with a `move` function
 * - `move` updates CSS variables which affect its `transform` property.
 * - You can set the `--highlight-outline-color` variable to change its color.
 * - Its x,y position is set to a `---translation` variable so you can apply other transforms e.g. `transform: var(--translation) scale(0.8)`
 */
// todo: should this be a react component?
export const createHighlighter = (grid: HTMLElement) => {
  const highlighter = document.createElement('div')
  highlighter.className = 'cellHighlighter'
  grid.appendChild(highlighter)
  return {
    element: highlighter,
    move: (cell: { x: number; y: number }) => {
      highlighter.style.setProperty('--focused-x', `${cell.x}`)
      highlighter.style.setProperty('--focused-y', `${cell.y}`)
    },
  }
}
