import { type EventPostOutput, type EventPostInput, type GetEventsInput } from './types'

export const postEvents = async (input: EventPostInput) => {
  const res = await fetch('/event', {
    method: 'post',
    body: JSON.stringify(input),
    headers: {
      'content-type': 'application/json',
    },
  })
  if (!res.ok) {
    const output = await res.clone().text()
    throw new Error(
      `POST /event failed with ${res.status}:\nInput: ${JSON.stringify(input)}\nOutput: ${output}`,
    )
  }

  const output: EventPostOutput = await res.json()
  return output
}

export const getEvents = async (input: GetEventsInput) => {
  const res = await fetch(`/event?solution_id=${input.solution_id}`)
  if (!res.ok) {
    throw new Error(`GET /event failed with ${res.status}: ${await res.clone().text()}`)
  }

  const output: EventPostOutput = await res.json()
  return output
}
