import { createBrowserClient } from '@supabase/ssr'
import { Database } from '~/db'
import { throwOnErrorClientWrapper } from './shared'

export const createNonThrowingBrowserClient = (options: Parameters<typeof createBrowserClient>[2]) => {
  return createBrowserClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    options,
  )
}

export type NonThrowingBrowserClient = ReturnType<typeof createNonThrowingBrowserClient>

export const createClientComponentClient = (options?: Parameters<typeof createBrowserClient>[2]) => {
  const client = createNonThrowingBrowserClient(options)
  return throwOnErrorClientWrapper(client)
}
