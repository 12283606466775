import { PostgrestClient } from '@rebundled/postgrest-js' // got impatient waiting for https://github.com/supabase/postgrest-js/pull/494 so I published it
import { type Database } from '~/db'
import { type NonThrowingServerClient } from './server'
import { type NonThrowingBrowserClient } from './browser'

export type NonThrowingClient = NonThrowingServerClient | NonThrowingBrowserClient

export function throwOnErrorClientWrapper(client: NonThrowingClient) {
  // @ts-expect-error `.rest` is protected
  const { rest } = client

  const wrapper = new PostgrestClient<Database>(rest.url, {
    fetch: rest.fetch,
    headers: rest.headers,
    schema: rest.schemaName,
  }).throwOnError()

  type Params<K extends keyof NonThrowingClient> = Parameters<Extract<NonThrowingClient[K], Function>>

  return Object.assign(wrapper, {
    nonThrowing: client,
    channel: (...args: Params<'channel'>) => client.channel(...args),
    getChannels: (...args: Params<'getChannels'>) => client.getChannels(...args),
    removeChannel: (...args: Params<'removeChannel'>) => client.removeChannel(...args),
    removeAllChannels: (...args: Params<'removeAllChannels'>) => client.removeAllChannels(...args),
    realtime: client.realtime,
    auth: client.auth,
  })
}
