'use client'
import { Clue, CrosswordModel } from 'crosswords-js/src/index.mjs'

export type ClueCode = string & { __clueCode: never }

export type Answers = Record<ClueCode, string>
/** Given a model, return a record of (partial) answers. Can be used for persistnig so users can restore their progress when they come back */

export const serializeableAnswers = (model: CrosswordModel): Answers => {
  const kvps = model.acrossClues
    .concat(model.downClues)
    .map((c: Clue) => ({
      clueId: c.clueId,
      answer: c.cells
        .map((cell: any) => cell.cellElement?.querySelector('input')?.value || cell.answer)
        .join(''),
      clue: c,
    }))
    .map((c) => [c.clueId, c.answer] as [ClueCode, string])
  return Object.fromEntries(kvps)
}
