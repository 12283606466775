import Lottie, { LottieProps } from 'react-lottie-player'
import confettiData from './lottie/confetti_iniga_falco.json'
import drawingData from './lottie/drawing_abdul_latif.json'
import thinkingData from './lottie/thinking_eevin_jennings.json'
import robotArmsData from './lottie/robot_arms_vlue_stefanovic.json'
import dancingDogData from './lottie/dancingdog_satishkumar.json'
import partyPenguinData from './lottie/partypenguin_alexbradt.json'
import { CSSProperties } from 'react'

export const Confetti = (props?: LottieProps) => <Lottie animationData={confettiData} {...props} />
export const Drawing = (props?: LottieProps) => <Lottie animationData={drawingData} {...props} />

export const Overlayed =
  (Component: React.FC<LottieProps>) =>
  (props: LottieProps & { className?: string; style?: CSSProperties }) => (
    <div
      className={props.className}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        visibility: props.play ? 'visible' : 'hidden', // needed otherwise can be seen under transparent elements
        zIndex: 1, // overlay on top of stuff (hopefully)
        ...props.style,
      }}
    >
      <Component {...props} />
    </div>
  )

export const ConfettiOverlayed = Overlayed(Confetti)
export const DrawingOverlayed = Overlayed(Drawing)
export const ThinkingOverlayed = Overlayed((props) => <Lottie animationData={thinkingData} {...props} />)
export const RobotArmsOverlayed = Overlayed((props) => <Lottie animationData={robotArmsData} {...props} />)
export const DancingDogOverlayed = Overlayed((props) => <Lottie animationData={dancingDogData} {...props} />)
export const PartyPenguinOverlayed = Overlayed((props) => (
  <Lottie animationData={partyPenguinData} {...props} />
))
