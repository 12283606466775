'use client'

import { VerbalistDB } from '~/db'

// better approach: https://supabase.com/docs/guides/auth/managing-user-data#advanced-techniques

export const claimSolutions = (db: VerbalistDB) => {
  db.auth.onAuthStateChange(async (event, session) => {
    const storageJson = localStorage.getItem('verbalist.solution_participant_nickname.v1') || '{}'
    const { nick } = JSON.parse(storageJson) as { nick?: string }
    if (!nick) return
    const userId = session?.user.id
    if (event !== 'SIGNED_IN' || !userId) return
    const updated = await db.from('participant').update({ user_id: userId }).eq('nickname', nick).select('*')
    console.log('updated', updated)
  })
}
