import { CrosswordDefinition } from 'crosswords-js/src/index.mjs'

const addClueLength = <T extends { solution: string; clue: string }>(clue: T): T => {
  if (clue.clue.match(/\([\d,]+\)$/)) return clue
  return {
    ...clue,
    clue: `${clue.clue} (${clue.solution
      .split(' ')
      .map((word) => word.length)
      .join(', ')})`,
  }
}

export const withDefinitionLengths = (definition: CrosswordDefinition): CrosswordDefinition => ({
  ...definition,
  acrossClues: definition.acrossClues.map(addClueLength),
  downClues: definition.downClues.map(addClueLength),
})

export const withAnswers = (
  definition: CrosswordDefinition,
  answers: Record<string, string>,
): CrosswordDefinition => ({
  ...definition,
  acrossClues: definition.acrossClues.map((clue) => {
    const code = `${clue.clue.split('.')[0]}a`
    const answer = answers[code]
    return { ...clue, ...(answer && { answer }) }
  }),
  downClues: definition.downClues.map((clue) => {
    const code = `${clue.clue.split('.')[0]}d`
    const answer = answers[code]
    return { ...clue, ...(answer && { answer }) }
  }),
})

export const withoutAnswers = (definition: CrosswordDefinition): CrosswordDefinition => ({
  ...definition,
  acrossClues: definition.acrossClues.map((c) => ({ ...c, answer: '' })),
  downClues: definition.downClues.map((c) => ({ ...c, answer: '' })),
})
