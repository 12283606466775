'use client'

import { Session } from '@supabase/supabase-js'
import React from 'react'
import { createClientComponentClient } from './helpers/browser'
import { claimSolutions } from '~/components/crossword/Claimer'

// idea for this thing: include a session id in headers, which can be used in access policies to allow anonymous access
const sessionHeadersKey = 'supabase-headers.0.0.0'
const getSessionHeaders = (): Record<string, string> | null => {
  const sessionHeadersJson = sessionStorage.getItem(sessionHeadersKey) || null
  try {
    return sessionHeadersJson && JSON.parse(sessionHeadersJson)
  } catch {
    return null
  }
}
const setSessionHeaders = (headers: Record<string, string>) =>
  sessionStorage.setItem(sessionHeadersKey, JSON.stringify(headers))
const addSessionHeader = (name: string, value: string) => {
  const old = getSessionHeaders()
  setSessionHeaders({ ...old, [name]: value })
}

const fetchWithSessionHeaders: typeof fetch = async (input, init) => {
  const sessionHeaders = getSessionHeaders()
  if (!sessionHeaders) return fetch(input, init)
  const defaultHeaders =
    typeof init?.headers?.entries === 'function'
      ? Object.fromEntries(init.headers.entries())
      : { ...init?.headers }
  return fetch(input, {
    ...init,
    headers: { ...defaultHeaders, ...sessionHeaders },
  })
}

export const useSupabase = (props?: { session?: Session | null }) => {
  const [supabase] = React.useState(() => {
    return createClientComponentClient({
      // have to define cookies, but https://supabase.com/docs/guides/auth/server-side/creating-a-client?environment=client-component doesn't tell me what to set it to
      // update: 2023-11-09 - maybe we don't have to anymore?
      cookies: {},
      global: {
        fetch: fetchWithSessionHeaders,
      },
    })
  })
  const [session, setSession] = React.useState(props?.session)

  // todo: find out if there's a better way to do this. At worst, const session = useSession() provided by a supabase lib would be nice
  // seems this is the recommended way: https://supabase.com/docs/guides/auth/quickstarts/react
  React.useEffect(() => {
    supabase.auth.getSession().then(({ data }) => setSession(data.session))

    const authState = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    claimSolutions(supabase)

    return () => authState.data.subscription.unsubscribe()
  }, [supabase])

  return { supabase, session, addHeader: addSessionHeader }
}
