'use client'
import {
  Clue,
  ClueCell,
  ControllerInstance,
  CrosswordDefinition,
  CrosswordModel,
  CrosswordsJS,
} from 'crosswords-js/src/index.mjs'
import { EventEmitter } from 'events'
import { createHighlighter } from './highlighter'
import { Answers, ClueCode, serializeableAnswers } from './answers'
import { tracing } from 'crosswords-js/src/helpers.mjs'
import { testCrossword } from 'crosswords-js/src/crossword-controller-helpers.mjs'

type TypedEventEmitter<T extends Record<string, {}>> = {
  on: <K extends keyof T>(event: K, listener: (value: T[K]) => void) => void
  emit: <K extends keyof T>(event: K, value: T[K]) => void
}

export type CrosswordControllerXInstance = ControllerInstance &
  Omit<CrosswordControllerX, keyof ControllerInstance>

export const parseXY = (xy: string) => {
  const [x, y] = xy.replace(/^\(/, '').replace(/\)$/, '').split(',').map(Number)
  return typeof x === 'number' && typeof y === 'number' ? { x, y } : null
}

/** Adds a couple of classes to the default crosswords-js controller elements */
export class CrosswordControllerX extends CrosswordsJS.Controller {
  events = new EventEmitter() as TypedEventEmitter<{
    currentCellUpdated: ClueCell
    answersUpdated: Answers
    cellValueUpdated: { x: number; y: number; value: string }
    clueSolved: { clueId: string }
  }>

  get #model() {
    return this.model as CrosswordModel
  }
  get #allClues() {
    return this.#model.acrossClues.concat(this.#model.downClues) as Clue[]
  }

  get #allCells() {
    return Array.from(new Set(this.#allClues.flatMap((clue) => clue.cells)))
  }

  setGridCell(cellId: string, character: string): void {
    try {
      cellId = `(${cellId})`
      super.setGridCell(cellId, character)
    } catch (err) {
      throw new Error(`error setting cell ${cellId} to ${character}: ${err}`)
    }
  }

  #cellClues = {} as Record<string, Clue[]>
  getCellClues(coordinates: { x: number; y: number }) {
    return this.#cellClues[`${coordinates.x},${coordinates.y}`] || []
  }

  static from(definition: CrosswordDefinition, gridParent: HTMLElement, cluesParent: HTMLElement) {
    const instance = new CrosswordControllerX(definition, gridParent, cluesParent)
    return instance as unknown as CrosswordControllerXInstance
  }

  #clueStartPositions = {} as Record<ClueCode, { x: number; y: number }>

  public getCellXY = (clueId: ClueCode, index: number) => {
    const start = this.#clueStartPositions[clueId]
    if (!start) throw new Error(`no clue start for ${clueId}`)
    return clueId.endsWith('a') ? `${start.x + index},${start.y}` : `${start.x},${start.y + index}`
  }

  constructor(
    public definition: CrosswordDefinition,
    gridParent: HTMLElement,
    cluesParent: HTMLElement,
  ) {
    super(definition, gridParent, cluesParent)

    tracing(window.location.search.includes('trace=true'))

    const audio = new Audio('/sounds/pixabay/winfantasia-6912.mp3')

    const model = this.model as CrosswordModel
    this.#allClues.forEach((clue) => {
      this.#clueStartPositions[clue.clueId] = { x: clue.cells[0]!.x, y: clue.cells[0]!.y }
      clue.cells?.forEach((cell, i) => {
        const xy = `${cell.x},${cell.y}`
        // todo: remove if https://github.com/dwmkerr/crosswords-js/pull/50 goes in
        cell.cellElement.dataset.xy = `${cell.x},${cell.y}`
        this.#cellClues[xy] = this.#cellClues[xy] || []
        this.#cellClues[xy]!.push(clue)
      })

      clue.wordLengths.slice(0, -1).reduce((prev, wordLength) => {
        const index = prev + wordLength
        const type = clue.isAcross ? 'across' : 'down'
        clue.cells[index - 1]?.cellElement?.classList.add(`before-${type}-word-separator`)
        clue.cells[index]?.cellElement?.classList.add(`after-${type}-word-separator`)
        return index
      }, 0)
    })

    const grid = this.gridView as HTMLDivElement

    if (!grid) console.warn('no grid')
    if (!window.location.href.includes('old-focus') && grid) {
      // this doesn't work, was trying to have a separate animated cursor. might want to try css anchors instead
      grid.classList.add('slideyFocusGrid')
      const highlighter = createHighlighter(grid)
      highlighter.move(this.#allCells[0]!)
      this.events.on('currentCellUpdated', highlighter.move)
    }

    const gridContainer = gridParent.parentNode as HTMLElement
    gridContainer.style.setProperty('--row-count', `${definition.height}`)
    gridContainer.style.setProperty('--column-count', `${definition.width}`)

    const onInput = async (ev: { target: EventTarget | null }) => {
      const answers = serializeableAnswers(this.model)
      this.events.emit('answersUpdated', answers)
      const cell = findNodeUp(ev.target as HTMLElement, (node) => !!node.dataset.xy)
      const value = cell?.querySelector('input')?.value
      const coordinates = parseXY(cell?.dataset.xy || '')
      if (coordinates && value) {
        this.events.emit('cellValueUpdated', { ...coordinates, value })
        // todo: make this a first-class event - and keep track of when it _changed_
        this.getCellClues(coordinates).forEach((clue) => {
          const isGood = clue.cells.every((c) => c.cellElement.querySelector('input')?.value === c.solution)
          const direction = clue.isAcross ? 'across' : 'down'
          const clueSolved = `clue-solved-${direction}`
          clue.cells.forEach((cell, i) => {
            if (testCrossword(this, false) === 0) return
            this.gridView.classList.remove('fully-solved')
            const distance = Math.sqrt((cell.x - coordinates.x) ** 2 + (cell.y - coordinates.y) ** 2)
            if (isGood) setTimeout(() => cell.cellElement.classList.add(clueSolved), distance * 35)
            else cell.cellElement.classList.remove(clueSolved)
          })
        })
      }
    }
    gridParent.addEventListener('keypress', onInput)
    gridParent.addEventListener('keydown', (ev) => {
      if (ev.key === 'Backspace') onInput(ev) // https://stackoverflow.com/a/30108293
    })

    this.addEventsListener(['crosswordSolved'], () => {
      this.gridView.classList.add('fully-solved')
      this.#allCells.forEach((cell, i) => {
        const distance = Math.sqrt((cell.x - this.currentCell.x) ** 2 + (cell.y - this.currentCell.y) ** 2)
        setTimeout(() => cell.cellElement.classList.add('celebrate'), distance * 35)
        setTimeout(() => cell.cellElement.classList.remove('celebrate'), 5000 - distance * 35)
      })
      audio.play()
    })
  }

  get currentCell() {
    return super.currentCell
  }
  set currentCell(cell: ClueCell) {
    super.currentCell = cell
    this.events?.emit('currentCellUpdated', cell)
  }

  #highlighters = {} as Record<string, ReturnType<typeof createHighlighter>>

  createHighlighter(key: string, vars: { color: string; label?: string }) {
    const highlighter = createHighlighter(this.gridView)
    highlighter.element.dataset.highlighterKey = key
    highlighter.element.style.setProperty('--highlight-outline-color', vars.color)
    if (vars.label) highlighter.element.style.setProperty('--highlighter-label', vars.label)
    this.#highlighters[key] = highlighter
    return highlighter
  }

  findHighlighter(key: string) {
    return this.#highlighters[key]
  }

  crupdateHiglighter(key: string, vars: { color: string; label?: string }) {
    return this.findHighlighter(key) || this.createHighlighter(key, vars)
  }

  removeHighlighter(key: string) {
    const highlighter = this.#highlighters[key]
    if (!highlighter) return
    highlighter.element.remove()
    delete this.#highlighters[key]
  }

  serialize() {
    return {
      cells: Object.fromEntries(
        this.#allCells.map((cell) => [
          cell.cellElement.dataset.xy,
          cell.cellElement.querySelector('input')?.value,
        ]),
      ) as Record<string, string>,
    }
  }

  get model() {
    return super.model as CrosswordModel
  }

  xy() {
    return this.model.cells.map((row) =>
      row.map((cell: ClueCell) => cell.cellElement.querySelector('input')?.value || ''),
    )
  }

  restore(state: ReturnType<typeof this.serialize>) {
    Object.entries(state.cells || {}).forEach(([cellId, value]) => this.setGridCell(cellId, value))
  }
}

export type ControllerState = ReturnType<CrosswordControllerX['serialize']>

const findNodeUp = <Required extends boolean = false>(
  node: HTMLElement,
  match: (node: HTMLElement) => boolean,
  { required }: { required?: Required } = {},
) => {
  let result: HTMLElement | null = node
  while (result && !match(result)) {
    result = result.parentElement
  }
  if (!result && required) throw new Error('no match found')

  return result as Required extends true ? HTMLElement : HTMLElement | null
}
