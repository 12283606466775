'use client'
import React from 'react'
import { HelpInput } from '~/app/help/route'
import QRCode from 'react-qr-code'
import { Button } from '~/components/ui/button'
import { CrosswordControllerXInstance } from './controller'
import { DrawingOverlayed, RobotArmsOverlayed, ThinkingOverlayed } from '../animations'
import { useMutation, useQuery } from '@tanstack/react-query'

export const QRSharer = ({ href, text }: { href: string; text: string }) => {
  return (
    <>
      <Button title="share" data-url={href} onClick={() => navigator.share({ url: href, title: text, text })}>
        share
      </Button>
      <QRCode value={href} />
    </>
  )
}
export type HintProps = {
  title: string
  getInput: () => HelpInput | null
  onSuccess: (output: any) => void
  children: string
}

export const Cheatbar = ({ controller }: { controller: CrosswordControllerXInstance }) => {
  const [hints, setHints] = React.useState({} as Record<string, string[]>)
  const help = useMutation({
    mutationFn: async (input: HelpInput) => {
      const res = await fetch('/help', {
        method: 'post',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(input),
      })
      return res.json()
    },
    onSuccess: (data) => {
      if (typeof data.hint === 'string') {
        // delay a tiny bit so animations have time to hide
        setTimeout(() => alert(data.hint), 100)
      } else {
        console.warn(`Unexpedcted hint type ${data.hint}`, data)
      }
    },
  })
  return (
    <div className="cheatbar mt-2">
      <ThinkingOverlayed
        className={help.variables?.type == 'clue' && help.isLoading ? '' : 'hidden'}
        play={help.isLoading}
      />
      <RobotArmsOverlayed
        className={help.variables?.type != 'clue' && help.isLoading ? '' : 'hidden'}
        play={help.isLoading}
      />
      <Button title="check crossword" onClick={() => controller?.testCrossword()}>
        🤓
      </Button>
      <Button title="check clue" onClick={() => controller?.testCurrentClue()}>
        🤔
      </Button>
      <Button title="letter" onClick={() => controller?.revealCurrentCell()}>
        💌
      </Button>
      <Button title="cheat" onClick={() => controller?.revealCurrentClue()}>
        👹
      </Button>
      <Button title="cheat a lot" style={{ flex: 'none' }} onClick={() => controller?.revealCrossword()}>
        👹👹
      </Button>

      <Button
        title="wipe"
        onClick={() => {
          if (!confirm('Wipe everything?')) return
          controller?.resetCrossword()
        }}
      >
        🗑️
      </Button>
      <Button
        title="hint"
        onClick={async () => {
          const { headNumber: number, isAcross } = controller.currentClue!
          const key = `${number}.${isAcross}`
          const { source, ...definition } = controller.definition
          const hint = await help.mutateAsync({
            type: 'clue',
            definition,
            number,
            isAcross,
            hints: hints[key],
          })
          setHints((old) => ({
            ...old,
            [key]: [...(old[key] || []), hint.hint].filter(Boolean),
          }))
        }}
      >
        🪄
      </Button>
      <Button
        title="anagram"
        onClick={async () => {
          const word = prompt('Anagram of?')
          if (word) help.mutate({ type: 'anagram', word })
        }}
      >
        🙃
      </Button>
      <Button
        title="synonym"
        onClick={async () => {
          const word = prompt('Synonym of?')
          if (word) help.mutate({ type: 'synonym', word })
        }}
      >
        📚
      </Button>
      <Button
        title="collab"
        onClick={async () => {
          alert('unimplemented')
          // console.log({ _gameId })
          // if (!_gameId) return alert('no game id')
          // const collab = await supabase.from('collab').insert({ host: _gameId }).select()
          // console.log({ collab })
          // setCollabId(collab.data?.[0]?.id)
        }}
      >
        🤝
      </Button>
    </div>
  )
}
